import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { post } from '../../util/api.js';
import { FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

import css from './FollowStats.module.css';

const FollowStats = props => {
    const { author, currentUser, onUpdateProfile, className } = props;

    const initialFollowersList = author?.attributes?.profile?.publicData?.followersList;
    const { followingList = [] } = author?.attributes?.profile?.publicData;

    const [followersList, setFollowersList] = useState();

    useEffect(() => {
        if (!followersList) {
            setFollowersList(initialFollowersList);
        }
    }), []

    const isOwnProfile = author?.id?.uuid === currentUser?.id?.uuid;
    const isFollowing = followersList?.includes(currentUser?.id.uuid)

    const handleAddAsFollower = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const authorId = new UUID(author?.id?.uuid);
        const currentUserFollowingList = currentUser?.attributes?.profile?.publicData?.followingList || [];


        const updatedFollowersList = followersList?.includes(currentUser.id.uuid)
            ? followersList?.filter(entry => entry !== currentUser.id.uuid)
            : followersList
                ? [...followersList, currentUser.id.uuid]
                : [currentUser.id.uuid];

        const updatedFollowingList = currentUserFollowingList?.includes(authorId.uuid)
            ? currentUserFollowingList?.filter(entry => entry !== authorId.uuid)
            : currentUserFollowingList
                ? [...currentUserFollowingList, authorId.uuid]
                : [authorId.uuid];

        post('/api/add-as-follower',
            {
                authorId: authorId,
                followers: updatedFollowersList,
            });
        setFollowersList(updatedFollowersList);
        onUpdateProfile({ publicData: { followingList: updatedFollowingList } });
    };

    return (
        <div className={classNames(css.root, className)}>
            <FormattedMessage id="FollowStats.UsersFollowers" values={{ users: followersList?.length }} />
            <FormattedMessage id="FollowStats.UsersFollowing" values={{ users: followingList?.length }} />

            <button
                className={classNames(css.ctaButton, { [css.isFollowing]: isFollowing })}
                disabled={isOwnProfile}
                onClick={handleAddAsFollower}
            >
                {isFollowing ?
                    <FormattedMessage id="FollowStats.Following" />
                    :
                    <FormattedMessage id="FollowStats.Follow" />
                }
            </button>
        </div>
    );
};

export default FollowStats;