
import React, { useState, useEffect } from 'react';
import css from './AddToFavoritesButton.module.css';
import FavoriteOnIcon from '@mui/icons-material/Favorite';
import FavoriteOffIcon from '@mui/icons-material/FavoriteBorder';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { IconSpinner } from '..';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
    clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const AddToFavoritesButtonComponent = props => {
    const { listingId, currentUser, isOwnListing, history, onUpdateCurrentUser } = props;

    if (isOwnListing) {
        return null;
    }

    const [isFavorite, setIsFavorite] = useState(false);
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        if (currentUser?.id?.uuid) {
            const favorites = currentUser?.attributes?.profile?.privateData?.favorites;
            if (favorites?.includes(listingId)) {
                setIsFavorite(true);
            }
        }
    }, [currentUser?.id?.uuid, listingId]);

    const AddToFavorites = action => {
        setIsBusy(true);
        if (!currentUser?.id?.uuid) {
            return history.push('/login');
        }
        if (!listingId || !currentUser?.id?.uuid) {
            return null;
        }
        return sdk.currentUser
            .show()
            .then(res => {
                const currentFavorites =
                    res?.data?.data?.attributes?.profile?.privateData?.favorites || [];

                if (currentFavorites.includes(listingId) && action === 'add') {
                    setIsBusy(false);
                    return setIsFavorite(true);
                }

                if (action === 'add') {
                    return sdk.currentUser
                        .updateProfile({
                            privateData: {
                                favorites: [...currentFavorites, listingId],
                            },
                        })
                        .then(resp => {
                            setIsBusy(false);
                            return setIsFavorite(true);
                        })
                        .catch(e => {
                            console.log(e)
                            setIsBusy(false);
                        });
                } else {
                    const newFavorites = [...currentFavorites].filter(id => id !== listingId);

                    return sdk.currentUser
                        .updateProfile({
                            privateData: {
                                favorites: newFavorites,
                            },
                        })
                        .then(resp => {
                            setIsBusy(false);
                            return setIsFavorite(false);
                        })
                        .catch(e => {
                            console.log(e)
                            setIsBusy(false);
                        });
                }
            })
            .catch(e => {
                console.log(e)
                setIsBusy(false);
            });
    };

    return (
        <div className={css.favoritesButtonWrapper}>

            {isBusy ? <IconSpinner />
                :
                !isFavorite ? (
                    <FavoriteOffIcon
                        onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            AddToFavorites('add');
                        }}
                        className={css.favoritesButtonNotSelected}
                        shape-rendering="geometricPrecision"
                        htmlColor="var(--marketplaceColor)"
                    />
                ) : (
                    <FavoriteOnIcon
                        onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            AddToFavorites('remove');
                        }}
                        className={css.favoritesButtonSelected}
                        shape-rendering="geometricPrecision"
                        htmlColor="var(--marketplaceColor)"
                    />
                )
            }
        </div>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => ({
    onUpdateCurrentUser: () => dispatch(fetchCurrentUser())
});

const AddToFavoritesButton = compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    injectIntl
)(AddToFavoritesButtonComponent);

export default AddToFavoritesButton;