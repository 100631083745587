export const productConditions = [
    { option: "Brand New", label: "Brand New", info: "Unused with original packaging and tags" },
    { option: "Like New", label: "Like New", info: "Mint condition pre owned or new without tags" },
    { option: "Used-Excellent", label: "Used-Excellent", info: "Lightly used by no noticable flaws" },
    { option: "Used-Good", label: "Used-Good", info: "Minor flaws or sign of wear (To be noted in the descripton and photos)" },
    { option: "Used-Fair", label: "Used-Fair", info: "Obvious flaws or sign of wear (To be noted in the descripton and photos)" },
]

export const getInfoForCondition = (condition) => {
    return productConditions.find(p => p.option === condition).info;
}

export const getProductConditionsFilter = () => {
    return productConditions.map(c => ({ option: c.option, label: c.option }));
}